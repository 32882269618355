import { WithContext, Organization, BreadcrumbList } from "schema-dts";
import { configApi } from "@shared/lib/configApi";
import { PROJECT_NAME } from "@shared/config/GlobalText";
import { RouterConfig } from "@shared/lib/routerConfig";

export const organizationSchema: WithContext<Organization> = {
    "@context": "https://schema.org",
    "@type": "Organization",
    name: PROJECT_NAME,
    url: configApi.API_PUBLIC_DOMAIN,
    logo: `${configApi.API_PUBLIC_DOMAIN}/static/default-logo.png`,
    sameAs: [
        "https://vk.com/likvicom",
        "https://www.youtube.com/@Likvicom",
        "https://t.me/likvicom"
    ],
    contactPoint: {
        "@type": "ContactPoint",
        telephone: "+7-925-151-3156",
        contactType: "Customer service"
    }
};

export const breadcrumbProductSchema: WithContext<BreadcrumbList> = {
    "@context": "https://schema.org",
    "@type": "BreadcrumbList",
    itemListElement: [
        {
            "@type": "ListItem",
            position: 1,
            item: {
                "@type": "Thing",
                "@id": configApi.API_PUBLIC_DOMAIN,
                name: PROJECT_NAME
            }
        },
        {
            "@type": "ListItem",
            position: 2,
            item: {
                "@type": "Thing",
                "@id": `${configApi.API_PUBLIC_DOMAIN}${RouterConfig.CATALOG}`,
                name: "Каталог"
            }
        }
    ]
};