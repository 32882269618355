import React, { FC } from "react";
import { IconProps } from "@shared/ui/Icon/ui/types";

export const RightIcon: FC<IconProps> = ({ className }) => (
    <svg
        className={className}
        width={8}
        height={8}
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 7 11"
    >
        <path d="M.4343.5657a.8.8 0 0 1 1.1314 0l4.5255 4.5255a.8.8 0 0 1 0 1.1313L1.5657 10.748A.8.8 0 0 1 .4343 9.6167l3.9598-3.9598L.4343 1.697a.8.8 0 0 1 0-1.1314Z" />
    </svg>
);