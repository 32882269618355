import Image from "next/image";
import React, { useState } from "react";
import { ALL_CITIES } from "@components/Features/Catalog/catalogUtils";
import { configApi } from "@shared/lib/configApi";
import { BrandsListLabels, ITEMS_QNT_SHORT_MODE } from "./constants";
import { PopularBrands } from "./config";
import classes from "./BrandsList.module.scss";

const BrandsList = () => {
    const [isShortMode, setIsShortMode] = useState(true);

    const toggleMode = (e: React.MouseEvent) => {
        e.preventDefault();
        setIsShortMode(!isShortMode);
    };

    const data = isShortMode ? PopularBrands.slice(0, ITEMS_QNT_SHORT_MODE) : PopularBrands;

    return (
        <div className={classes.BrandsList}>
            <ul className={classes.BrandsList__inner}>
                {data.map((el) => {
                    const logoUrl = `${configApi.YANDEX_STORAGE_URL}${el.alias}.png`;
                    return (
                        <li className={classes.BrandsList__item} key={el.name}>
                            <a
                                className={classes["BrandsList__item-link"]}
                                href={`/catalog/${ALL_CITIES}/${encodeURIComponent(el.alias)}/all`}
                                aria-label={`Перейти к объявлениям ${el.name}`}
                            >
                                <span className={classes["BrandsList__item-wrapper"]}>
                                    <Image
                                        className={classes["BrandsList__item-icon"]}
                                        src={logoUrl}
                                        alt={`Логотип бренда ${el.name}`}
                                        width={50}
                                        height={20}
                                        quality={85}
                                    />
                                </span>
                                <span className={classes["BrandsList__item-label"]}>{el.name}</span>
                            </a>
                        </li>
                    );
                })}
            </ul>
            <a className={classes.BrandsList__toggler} onClick={toggleMode} href="#">
                {isShortMode ? BrandsListLabels.SHOW_ALL_LABEL : BrandsListLabels.SHOW_LESS_LABEL}
            </a>
        </div>
    );
};

export default BrandsList;