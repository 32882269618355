import React, { FC } from "react";
import { IconProps } from "@shared/ui/Icon/ui/types";

export const LeftIcon: FC<IconProps> = ({ className }) => (
    <svg
        className={className}
        width={8}
        height={8}
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 7 11"
    >
        <path d="M6.5657.5657a.8.8 0 0 0-1.1314 0L.9088 5.0912a.8.8 0 0 0 0 1.1313l4.5255 4.5255a.8.8 0 0 0 1.1314-1.1313L2.6059 5.6569 6.5657 1.697a.8.8 0 0 0 0-1.1314Z" />
    </svg>
);